* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  min-height: 100vh;
  width: 100vw;
}

.testModeFlag {
  display: inline-block;
  position: fixed;
  top: 40px;
  right: 10px;
  background: red;
  color: white;
  padding: 4px;
  border-radius: 4px;
}

.Inner__Header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
}

.Inner__Header > img {
  margin-right: 10px;
}

.Inner__Header > p {
  flex: 1;
}

.MainArea {
  padding: 10px;
}

.MainArea__Footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.DayPickerContainer {
  position: fixed;
  background: white;
  z-index: 10;
}

.App.Iframe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App.Iframe.light {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
}

.App.Iframe .Inner {
  overflow-x: hidden;
  width: 100vw;
  max-width: 660px;
  height: auto;
  max-height: 100vh;
  padding: 10px;
  background: #fff;
  border-radius: 4px;
}

@media screen and (max-width: 600px) {
  .App.Iframe .Inner {
    margin-top: 40px;
    max-height: 100vh;
  }
}

.FullScreen {
  display: grid;
  grid-template-columns: 1fr minmax(500px, 2fr);
  grid-template-rows: 60px auto 40px;
  grid-template-areas:
    'header header'
    'aside inner'
    'footer footer';
  grid-gap: 20px;
  padding: 20px;
  max-width: 1100px;
  margin: 0 auto;
}

.FullScreen .Inner {
  grid-area: inner;
  box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 4px;
}

.FullScreen .Header {
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FullScreen .Footer {
  opacity: 0.8;
  grid-area: footer;
  display: flex;
  justify-content: center;
  align-items: center;

  text-decoration: none;
  font-size: 12px;
  color: #121212;
}

.Iframe .Footer {
  opacity: 0.7;
  grid-area: footer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  text-decoration: none;
  font-size: 12px;
  color: #fff;
}

.Iframe .Footer.Dark {
  color: #121212;
}

.SideImage {
  grid-area: aside;
  background-position: center;
  background-size: cover;
}

@media screen and (max-width: 870px) {
  .FullScreen {
    grid-template-columns: 1fr;
    grid-template-rows: 60px auto 40px;
    grid-template-areas:
      'header'
      'inner'
      'footer';
  }

  .SideImage {
    display: none;
  }
}

.Inner {
  background: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 500px) {
  .FullScreen {
    padding: 10px;
  }
}

@media screen and (max-width: 600px) {
  .MainArea__Footer {
    flex-direction: column;
  }

  .MainArea__Footer > div {
    flex-direction: column;
    width: 100%;
  }

  .MainArea__Footer button {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0 !important;
  }
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: #fff;
  z-index: 10000;
}
